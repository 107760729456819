import React from "react";
import { ChangePasswordSettings } from "../../components/Settings";
import style from "./securitySettings.module.scss";

export default function SecuritySettings() {
  return (
    <>
      <ChangePasswordSettings />
    </>
  );
}
